import React from "react"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import Dribbble from "../images/social/dribbble.inline.svg"
import Instagram from "../images/social/instagram.inline.svg"
import Github from "../images/social/github.inline.svg"
import LinkedIN from "../images/social/linked_in.inline.svg"
import Twitter from "../images/social/twitter.inline.svg"
import Email from "../images/social/email.inline.svg"

import Quote from "../components/quote"
import Layout from "../components/layout"

// Construct the Social Links
const SocialLink = props => (
  <li>
    <a href={props.linkTo} target="_blank" rel="noopener noreferrer" className="social-button">
      {props.svgImage}
    </a>
  </li>
)
export default ({data}, props) => (  
  <Layout>
  <Helmet 
    bodyAttributes={{class:'about black'}}
    title="JayJo Design | Jeffrey Jorgensen | About Me"
  />   
    <div>
      <div className="section">
        <section className="about">
          <div className="wrapper">
            <h1 className="section-header white">About Me</h1>
            <p>Hey, I'm Jeffrey (nickname JayJo) and I'm a multidisciplinary designer nestled in the foothills of the Rocky Mountains just north of Denver. I wear a lot of hats, and I wear all of them pretty well.</p>
            <p>A lot of folks have said my personality and work-ethic is what sets me apart from other designers. Learning as much about my craft as I can is what pushes me every day.</p>
            <p>I'm currently working at <a href="https://proxy.com" target="_blank" rel="noopener noreferrer">Proxy</a>.</p>
            <ul className="social-buttons">
              <SocialLink
                linkTo={"https://dribbble.com/jayjo"}
                svgImage={<Dribbble />}
              />
              <SocialLink
                linkTo={"https://instagram.com/jayjo44"}
                svgImage={<Instagram />}
              />
              <SocialLink
                linkTo={"https://github.com/jayjo"}
                svgImage={<Github />}
              />
              <SocialLink
                linkTo={"https://www.linkedin.com/in/jayjo/"}
                svgImage={<LinkedIN />}
              />
              <SocialLink
                linkTo={"https://www.twitter.com/jayjo"}
                svgImage={<Twitter />}
              />
              <SocialLink
                linkTo={"mailto:jeff@jayjodesign.com?subject=I have a project, let's chat..."}
                svgImage={<Email />}
              />
            </ul>
          </div>
        </section>
        <section className="quote">
          <div className="wrapper narrow-med">
            <Quote
              quoteText="The engineering team is deeply appreciative of the work Jeff's done and we look forward to him continuing to enable a more collaborative environment."
              subject="- Joe Xavier, Former Mixpanel VP of Eng., 2016"
            />
          </div>
        </section>
      </div>
    </div>
  </Layout>
)

// Get the Site Meta Data & JayJo Links
export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`